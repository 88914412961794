//本地储存封装
export const getItem = name => {
    const data = window.localStorage.getItem(name)
        // data 不是 json 格式字符串
    try {
        //尝试把 data 转为 javaScr ipt对象
        return JSON.parse(data)
    } catch (err) {
        // data 不是 json 格式字符串，直接原样返回
        return data
    }
}

export const setItem = (name, value) => {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(name, value)
}

export const removeItem = name => {
    window.localStorage.removeItem(name)
}