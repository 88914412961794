import Vue from "vue";
import VueRouter from "vue-router";
import { Dialog } from "vant";
import store from "@/store/";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/"),
    meta: {
      requiresAuth: false
    } // 添加额外的自定义数据
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/layout/"),
    children: [
      {
        path: "", // 默认子路由
        name: "home",
        component: () => import("@/views/home/"),
        meta: {
          requiresAuth: false,
          title: "社区生活",
          pagetitle: "社区生活"
        }
      },
      {
        path: "/equityLogin", // 默认子路由
        name: "equityLogin",
        component: () => import("@/views/equityLogin/"),
        meta: {
          requiresAuth: false,
          title: "社区生活",
          pagetitle: "社区生活"
        }
      },
      {
        path: "/getuser",
        name: "getuser",
        component: () => import("@/views/getuser/"),
        meta: {
          requiresAuth: false,
          pagetitle: " plus本地生活会员",
          title: " plus本地生活会员"
        }
      },
      {
        path: "/member",
        name: "member",
        component: () => import("@/views/member/"),
        meta: {
          requiresAuth: false,
          pagetitle: " plus本地生活会员",
          title: " plus本地生活会员"
        }
      },
      {
        path: "/zgsy",
        name: "zgsy",
        component: () => import("@/views/zgsy/"),
        meta: {
          requiresAuth: false,
          pagetitle: "中国石油",
          title: "中国石油"
        }
      },
      {
        path: "/czq",
        name: "czq",
        component: () => import("@/views/czq/"),
        meta: {
          requiresAuth: false,
          pagetitle: "充值券",
          title: "充值券"
        }
      },
      {
        path: "/tieta",
        name: "tieta",
        component: () => import("@/views/tieta/"),
        meta: {
          requiresAuth: false,
          pagetitle: "铁塔",
          title: "铁塔"
        }
      },
      {
        path: "/pay",
        name: "pay",
        component: () => import("@/views/pay/"),
        meta: {
          requiresAuth: false,
          pagetitle: "微信支付",
          title: "微信支付"
        }
      },
      {
        path: "/goodslist",
        name: "goodslist",
        component: () => import("@/views/goodslist/"),
        meta: {
          requiresAuth: false,
          pagetitle: "商超搜索",
          title: "商超搜索"
        }
      },
      {
        path: "/goodslist1",
        name: "goodslist1",
        component: () => import("@/views/goodslist1/"),
        meta: {
          requiresAuth: false,
          pagetitle: "互联网权益券",
          title: "互联网权益券"
        }
      },
      {
        path: "/qa",
        name: "qa",
        component: () => import("@/views/qa/"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/vi",
        name: "vi",
        component: () => import("@/views/video/"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/quanyi",
        name: "quanyi",
        component: () => import("@/views/quanyi/"),
        meta: {
          requiresAuth: false,
          pagetitle: "免费权益",
          title: "免费权益"
        }
      },
      {
        path: "/ljjl",
        name: "ljjl",
        component: () => import("@/views/ljjl/"),
        meta: {
          requiresAuth: false,
          pagetitle: "领劵记录",
          title: "领劵记录"
        }
      },
      {
        path: "/ljjl1",
        name: "ljjl1",
        component: () => import("@/views/ljjl1/"),
        meta: {
          requiresAuth: false,
          pagetitle: "领劵记录",
          title: "领劵记录"
        }
      },
      {
        path: "/quanyiwjh",
        name: "quanyiwjh",
        component: () => import("@/views/quanyiwjh/"),
        meta: {
          requiresAuth: false,
          pagetitle: "权益激活",
          title: "权益激活"
        }
      },
      {
        path: "/search",
        name: "name",
        component: () => import("@/views/search/"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/ljsm",
        name: "ljsm",
        component: () => import("@/views/ljsm/"),
        meta: {
          requiresAuth: false,
          pagetitle: "领劵说明",
          title: "领劵说明"
        }
      },
      {
        path: "/description",
        name: "description",
        component: () => import("@/views/description/"),
        meta: {
          requiresAuth: false,
          pagetitle: "产品说明",
          title: "产品说明"
        }
      },
      {
        path: "/lingqu",
        name: "lingqu",
        component: () => import("@/views/lingqu/"),
        meta: {
          requiresAuth: false
        }
      },

      {
        path: "/selectcity",
        name: "selectcity",
        component: () => import("@/views/selectcity/"),
        meta: {
          requiresAuth: false,
          pagetitle: "选择城市"
        }
      },
      {
        path: "/my",
        name: "my",
        component: () => import("@/views/my/"),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  {
    path: "/scdetail",
    name: "scdetail",
    component: () => import("@/views/scdetail/"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/scdetail1",
    name: "scdetail1",
    component: () => import("@/views/scdetail1/"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/hcy",
    name: "hcy",
    component: () => import("@/views/hcy/"),
    meta: {
      requiresAuth: false,
      pagetitle: "和彩云"
    }
  },
  {
    path: "/vipTrip",
    name: "vipTrip",
    component: () => import("@/views/vipTrip/"),
    meta: {
      requiresAuth: false,
      pagetitle: "生活会员"
    }
  },
  {
    path: "/article/:articleId",
    name: "article",
    component: () => import("@/views/article/"),
    meta: {
      requiresAuth: true
    },
    // 将动态路由参数映射到组件的 props 中，无论是访问还是维护性都很方便
    props: true
  },
  {
    path: "/userprofile",
    name: "userprofile",
    component: () => import("@/views/user-profile"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/userchat",
    name: "userchat",
    component: () => import("@/views/user-chat"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  routes
});

// to: 要访问的页面右路信息
// from: 来自哪个页面的路由信息
// next: 放行的标记
router.beforeEach((to, from, next) => {
  // 校验登录状态，提示用户
  if (to.meta.requiresAuth) {
    // 如果已经登录了，就跳过
    if (store.state.user) {
      return next();
    }

    Dialog.confirm({
      title: "访问提示",
      message: "该功能需要登录才能进行访问，确认登录吗？"
    })
      .then(() => {
        // 确认执行这里
        router.replace({
          name: "login",
          query: {
            redirect: router.currentRoute.fullPath
          }
        });
      })
      .catch(() => {
        // 取消执行这里
        next(false);
      });
  } else {
    // 不需要登录状态的页面，直接过去
    next();
  }
});

export default router;
