<template>
  <div id="app">
    <!-- <div class="head">头条新闻</div> -->
    <!-- <van-button type="primary" block>头条新闻</van-button> -->
    <!-- 这个是一级路由，只缓存一级路由页面跳转，只对当前组件渲染有效 -->
    <keep-alive :include="cachePages">
      <router-view />
    </keep-alive>
  </div>
   <!-- <div id="app">
    <h3 class="head">头条新闻</h3>
    <router-view></router-view>
  </div> -->
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'App',
    components: {},

    computed: {
      ...mapState(['cachePages'])
    }
  }
</script>
<style lang="less">
  .head{
    height: 35px;
    text-align: center;
    padding-top: 13px;
    color: white;
    background-color: rgb(16, 123, 216);
  }
</style>
