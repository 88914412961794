// 初始化 dayjs 相关配置
import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

// 配置使用中文语言包
dayjs.locale('zh-cn') // 全局使用

// 配置使用处理相对时间
dayjs.extend(relativeTime)

// 把处理相对时间的代码包装成全局过滤器，可以在任何组件的模板中使用
// 在组件的模板中使用过滤器： {{ xxx | relativeTime }}
// 管道符前面的内容会作为参数传递给过滤器函数，返回值会渲染到使用过滤器的模板中
Vue.filter('relativeTime', value => {
    return dayjs(value).from(dayjs())
})

Vue.filter('datetime', value => {
    return dayjs(value).format('MM-DD HH:mm')
})