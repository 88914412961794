import Vue from "vue";
import Vuex from "vuex";
import { getItem, setItem } from "@/utils/storage.js";
import { getUserArea } from "../api/user";

const USER_KEY = "toutiao-user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: getItem(USER_KEY), //当前登录用户的登录状态（token等数据)
    //JSON.parse(window.localStorage.getItem('user'))

    cachePages: ["LayoutIndex"]
  },
  mutations: {
    setUser(state, data) {
      state.user = data;

      //为了防止页面刷新数据丢失，我们还需要把数据放到本地储存中，这里仅仅是为了持久化数据
      setItem(USER_KEY, state.user);
      // window.localStorage.setItem('user', JSON.stringify(state.user))
    },

    // 添加缓存页面
    addCachePage(state, pageName) {
      if (!state.cachePages.includes(pageName)) {
        state.cachePages.push(pageName);
      }
    },

    // 移出缓存页面
    removeCachePage(state, pageName) {
      const index = state.cachePages.indexOf(pageName);
      if (index !== -1) {
        state.cachePages.splice(index, 1);
      }
    }
  },
  actions: {
    AddUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit("setUser", data);
        if (data == null) return;
        let addressCity = getItem("addressCity");
        getUserArea({ baiduMapCity: addressCity }).then(res => {
          if (res.data.code == 0) {
            console.log("res.data", res.data);
            resolve();
            setItem("userArea", res.data.data);
            setItem("localtion", res.data.data);
          }
        });
      });
    }
  },
  modules: {}
});
